import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  IoTimer,
  IoPaperPlane,
  IoReceipt,
  IoBarChart,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";

import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { navigate } from "gatsby"

if (typeof window !== "undefined") {
  navigate('/recruitment');
}

const IndexPage = (props) => (
  <></>
);

export default IndexPage;
